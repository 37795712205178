.cp_tree-table .cp_tree-table_cell,
.cp_tree-table .cp_tree-table_header-cell {
  display: flex;
  align-items: center;
}

.cp_tree-table_header {
  margin-bottom: 15px;
}

.cp_tree-table .cp_tree-table_cell .toggle-button {
  position: relative;
  display: inline-block;
  border: none;
  height: 14px;
  vertical-align: middle;
  padding: 0 6px 0 0;
  margin-top: 3px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.cp_tree-table .cp_tree-table_cell .toggle-button::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #637381 transparent transparent transparent;
  padding: 0;
  vertical-align: middle;
}

.cp_tree-table .cp_tree-table_cell .toggle-button + span {
  vertical-align: middle;
}

.cp_tree-table .cp_tree-table_cell > .without-children > span {
  padding-left: 18px;
}
.cp_tree-table .cp_tree-table_cell > .with-children > span {
  font-weight: 400;
}

.cp_tree-table_row:hover {
  background-color: rgba(145, 158, 171, 0.08);
  border-radius: 5px;
}
